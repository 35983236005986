import React from 'react'

function Footer() {
  return (
    <div className='text-center'>
        All Rights Reserved by Smart Gate admin. Designed and Developed by <mark> Smart Gate </mark> 
    </div>
  )
}

export default Footer